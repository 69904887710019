import React, { useState } from 'react';
import { Collapse } from 'bootstrap';

const Tasks = () => {
  const [openTaskId, setOpenTaskId] = useState(null);

  const toggleTask = (taskId) => {
    if (openTaskId === taskId) {
      setOpenTaskId(null);
    } else {
      if (openTaskId !== null) {
        const oldCollapse = Collapse.getInstance(document.getElementById(`collapse-${openTaskId}`));
        oldCollapse.hide();
      }
      setOpenTaskId(taskId);
      const newCollapse = new Collapse(document.getElementById(`collapse-${taskId}`), {
        toggle: false,
      });
      newCollapse.show();
    }
  };

  return (
    <div className='tasks-container mt-5' style={{ height: '75vh', overflowY: 'auto' }}>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map((taskId) => (
        <div key={taskId} className='task-item card mb-3'>
          <div className='card-header'>
            <button
              className='btn btn-link w-100 text-start d-flex justify-content-between align-items-center'
              onClick={() => toggleTask(taskId)}
              aria-expanded={openTaskId === taskId}
              aria-controls={`collapse-${taskId}`}>
              <span>Task {taskId}</span>
              <i className={`bi ${openTaskId === taskId ? 'bi-chevron-up' : 'bi-chevron-down'}`}></i>
            </button>
          </div>
          <div id={`collapse-${taskId}`} className='collapse'>
            <div className='card-body'>
              <p className='mb-0'>
                Task {taskId} details go here. This is a placeholder for the task description, due date, priority, etc.
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Tasks;
