import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FaFileUpload, FaFolderOpen } from 'react-icons/fa';
import JSZip from 'jszip';
import { uploadFiles, uploadFileToSignedUrl } from '../services/service';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MultipleFilesDropzone = ({ onFilesAdded }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      onFilesAdded(acceptedFiles);
    },
    [onFilesAdded],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    noDirectory: true,
  });

  return (
    <div
      {...getRootProps()}
      className={`dropzone ${isDragActive ? 'active' : ''}`}
      style={{
        border: '2px dashed #222222',
        borderRadius: '10px',
        padding: '30px',
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: '#fff',
        transition: 'all 0.3s ease',
        marginBottom: '20px',
      }}>
      <input {...getInputProps()} />
      <FaFileUpload size={40} color='#222222' />
      <div className='mt-3'>
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag 'n' drop multiple files here, or click to select files</p>
        )}
      </div>
    </div>
  );
};

const FoldersDropzone = ({ onFilesAdded }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      onFilesAdded(acceptedFiles);
    },
    [onFilesAdded],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    noDrag: false,
    directoryMode: true,
  });

  return (
    <div
      {...getRootProps()}
      className={`dropzone ${isDragActive ? 'active' : ''}`}
      style={{
        border: '2px dashed #222222',
        borderRadius: '10px',
        padding: '30px',
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: '#f8f9fa',
        transition: 'all 0.3s ease',
      }}>
      <input {...getInputProps()} webkitdirectory='' mozdirectory='' />
      <FaFolderOpen size={40} color='#222222' />
      <div className='mt-3'>
        {isDragActive ? (
          <p>Drop the folders here ...</p>
        ) : (
          <p>Drag 'n' drop folders here, or click to select folders</p>
        )}
      </div>
    </div>
  );
};

const UploadSection = () => {
  const [allFiles, setAllFiles] = useState([]);
  const userData = useSelector((state) => state.user.userData);

  if (!userData)
    return (
      <div className='container mt-5 d-flex justify-content-center align-items-center' style={{ minHeight: '75vh' }}>
        <div className='alert alert-danger' role='alert'>
          No user data available
        </div>
      </div>
    );
  const handleFilesAdded = (newFiles) => {
    setAllFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleUpload = async () => {
    if (allFiles.length === 0) {
      toast.error('No files to upload');
      return;
    }

    let content;
    const zip = new JSZip();

    for (const file of allFiles) {
      if (file.type === 'application/zip') {
        content = file;
        break;
      } else {
        const fileContent = await file.arrayBuffer();
        zip.file(file.name, fileContent);
      }
    }

    const formData = new FormData();
    const currentDate = dayjs().format('YYYY-MM-DD_HH-mm-ss');
    const zipBlob = await zip.generateAsync({ type: 'blob' });
    formData.append('file', zipBlob, `${userData.user.name}-${currentDate}.zip`);

    try {
      const response = await uploadFiles(formData);
      if (!response || !response.blob || !response.blob.signed_url) {
        throw new Error('Invalid response from server');
      }

      await uploadFileToSignedUrl(content, response.blob.signed_url);

      toast.success('File uploaded successfully');
    } catch (error) {
      toast.error('Upload failed. Please try again.');
    } finally {
      setAllFiles([]);
    }
  };

  return (
    <div className='upload d-flex flex-column justify-content-center' style={{ height: '75vh' }}>
      <MultipleFilesDropzone onFilesAdded={handleFilesAdded} />
      <FoldersDropzone onFilesAdded={handleFilesAdded} />
      <button
        className='btn btn-dark'
        onClick={(e) => {
          e.preventDefault();
          handleUpload();
        }}>
        Upload Files
      </button>
      <ToastContainer />
    </div>
  );
};

export default UploadSection;
