import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useResetPasswordMutation } from '../services/service';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }
    try {
      await resetPassword({ token, password }).unwrap();
      toast.success('Password reset successfully');
      setPassword('');
      setConfirmPassword('');
    } catch (err) {
      console.error('Failed to reset password:', err);
      toast.error('Failed to reset password. Please try again.');
    }
  };

  return (
    <>
      <div className='container mt-5'>
        <div className='row mt-5'>
          <div className='col-md-6 d-flex flex-column justify-content-center'>
            <h1 className='display-2 mb-4'>Reset your password</h1>
            <p className='mb-5 w-75' style={{ fontSize: '1.5rem' }}>
              Enter your new password below to reset your account.
            </p>
          </div>
          <div className='col-md-6'>
            <div className='card p-4 shadow border-0'>
              <div className='card-body'>
                <h3 className='card-title mb-4'>Reset Password</h3>
                <form onSubmit={handleSubmit}>
                  <div className='mb-3'>
                    <label htmlFor='password' className='form-label'>
                      New Password
                    </label>
                    <input
                      type='password'
                      className='form-control'
                      id='password'
                      placeholder='Enter your new password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>
                  <div className='mb-3'>
                    <label htmlFor='confirmPassword' className='form-label'>
                      Confirm New Password
                    </label>
                    <input
                      type='password'
                      className='form-control'
                      id='confirmPassword'
                      placeholder='Confirm your new password'
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                  </div>
                  <button type='submit' className='btn btn-dark w-100 rounded-pill mt-4' disabled={isLoading}>
                    {isLoading ? 'Resetting...' : 'Reset Password'}
                  </button>
                </form>
                <div className='mt-3 text-center'>
                  <p>
                    Remember your password?{' '}
                    <Link to='/login' className='text-decoration-none'>
                      Back to Login
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ResetPassword;
