import axios from 'axios';
import { createApi } from '@reduxjs/toolkit/query/react';
import { store } from '../store/store';

const BASE_URL = process.env.REACT_APP_BASE_URL;

// auth requests start
const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: '' }) =>
  async ({ url, method, data, params, headers: customHeaders }) => {
    try {
      const ipAddress = localStorage.getItem('ip');
      const defaultHeaders = {
        'Request-Host': ipAddress || 'Unknown',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      };

      const headers = { ...defaultHeaders, ...customHeaders };

      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
        headers,
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err?.message,
        },
      };
    }
  };
export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: axiosBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: '/auth/login',
        method: 'POST',
        data: credentials,
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: '/user/logout',
        method: 'POST',
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
          localStorage.removeItem('token');
        } catch (error) {
          console.error('Logout failed:', error);
        }
      },
    }),
    register: builder.mutation({
      query: (data) => ({
        url: '/auth/register',
        method: 'POST',
        data,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (email) => ({
        url: '/auth/forgot-password',
        method: 'POST',
        data: email,
      }),
    }),
    refreshToken: builder.mutation({
      query: () => ({
        url: '/auth/refresh-token',
        method: 'POST',
        data: { refreshToken: store.refreshToken },
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ token, password }) => {
        if (!token) {
          console.error('Reset password token is missing');
        }
        return {
          url: '/user/reset-password',
          method: 'POST',
          data: { password },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
  }),
});
export const {
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  useForgotPasswordMutation,
  useRefreshTokenMutation,
  useResetPasswordMutation,
} = authApi;
// auth requests end

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    const ipAddress = localStorage.getItem('ip');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    if (ipAddress) {
      config.headers['Request-Host'] = ipAddress;
    } else {
      config.headers['Request-Host'] = 'Unknown';
    }

    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'application/octet-stream';
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export async function fetchUserData() {
  try {
    const response = await axiosInstance.get('/user/me');
    return response.data;
  } catch (error) {
    console.error('API error:', error);
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
    throw error;
  }
}

export async function updateUser(data) {
  try {
    const response = await axiosInstance.post('/user/update-profile', data);
    return response.data;
  } catch (error) {
    console.error('API error:', error);
    throw error;
  }
}

export async function resetPassword(data) {
  try {
    const response = await axiosInstance.post('/user/reset-password', data);
    return response.data;
  } catch (error) {
    console.error('API error:', error);
    throw error;
  }
}

export async function fetchListOfPlans() {
  try {
    const response = await axiosInstance.get('/subscription/plans');
    return response.data;
  } catch (error) {
    console.error('API error:', error);
    throw new Error('Failed to fetch list of plans');
  }
}

export async function uploadFiles(data) {
  try {
    const response = await axiosInstance.post(`/inference/upload`, {
      filename: data.get('file').name,
    });
    return response.data;
  } catch (error) {
    console.error('API error:', error);
    throw new Error('Failed to upload files');
  }
}

export async function uploadFileToSignedUrl(file, signedUrl) {
  try {
    const response = await axiosInstance.put(signedUrl, file);
    return response.data;
  } catch (error) {
    console.error('API error:', error);
    throw new Error('Failed to upload file to signed URL');
  }
}
